import styled from "styled-components";
import ssa_member from "../images/ssa_member.jpg";

const Wrapper = styled.div`
    background: #444444;
    color: #fff;
    min-width: 236px;
    max-width: 236px;
    @media (max-width: 576px) {
        display: none; 
    }    
`;

const LocationsHeader = styled.div`
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 20px;
    font-weight: bold; 
    justify-content: center;
    padding: 10px;
    width: 100%:
`;

const Location = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    padding: 10px;
`;

const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    padding: 10px;
`;

const Hours = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    padding: 10px;
`;

const ImageWrapper = styled.div`
    padding: 10px;
    background: #444444;
`;

const SideMenu = () => {
    return(
        <Wrapper>
            <LocationsHeader>Our Locations</LocationsHeader>
            <hr/>
            <Location>
                <b>Salem, NH Facility</b>
                Hampshire Road Storage, LLC<br/>
                9 Hampshire Road<br/>
                Salem, NH 03079<br/>
            </Location>
            <hr/>
            <Location>
                <b>Methuen, MA Facility</b><br/>
                Pine Street Storage, LLC<br/>
                11 Pine Street<br/>
                Methuen, MA 01844<br/>
            </Location>
            <hr/>
            <ContactInfo>
                Phone: <b>(603) 894-4631</b>
                Fax: <b>(603) 894-4638</b>
                Toll-Free: <b>(800) 891-4631</b>
            </ContactInfo>
            <hr/>
            <Hours>
                9:00 AM to 4:00 PM<br/>
                Monday through Thursday<br/><br/>
                <i>If you can not come during our office hours please call and we
                    will schedule an appointment.</i>
            </Hours>
            <ImageWrapper>
                <img src={ssa_member}/>
            </ImageWrapper>
        </Wrapper>
    );
};

export default SideMenu;