import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import React, {useEffect} from "react";
import AddressDisplay from "../components/AddressDisplay";
import MapPin from "../components/MapPin";
import {useWindowSize} from "../utils/hooks";

const directionsUrl = 'https://www.google.com/maps?f=d&hl=en&geocode&saddr&daddr=9+Hampshire+Road,+Salem,+NH+03079&sll=37.0625,-95.677068&sspn=46.543597,76.992187&ie=UTF8&t=h&z=16'
const largeMapUrl = 'https://www.google.com/maps?ll=42.742093,-71.201154&z=15&t=h&hl=en&gl=US&mapclient=embed&q=9+Hampshire+Rd+Methuen,+NH+01844';

const Header = styled.h2`
    color: #fff;
    @media (max-width: 576px) {
        margin-bottom: 0px;
    }
`;

const MapWrapper = styled.div`
    position: relative;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;   
    height: 100vh;
    width: 100%;
    padding: 40px;
`;

const DirectionsLink = styled.a`
    color: #fff;
    margin-bottom: 10px;
`;

const ViewLargeMapLink = styled.a`
    color: #fff;
    font-size: .75em;
`;

const Location = styled.div`
    display: flex;
    color: #fff;
    flex-direction: column;
    line-height: 1.5;
    padding-left: 10px;
    margin-bottom: 20px;
`;


const defaultProps = {
    center: {
        lat: 42.742084,
        lng: -71.201201
    },
    zoom: 16
};



const Salem = ({tracker}) => {

    const {width} = useWindowSize();

    useEffect( () => {

        // This line will trigger on a route change
        tracker.pageview(window.location.pathname + window.location.search);

    });

    return(
        <Wrapper>
            <Header>Salem, NH Facility</Header>
            {width <= 576 &&
                <Location>
                    Hampshire Road Storage, LLC<br/>
                    9 Hampshire Road<br/>
                    Salem, NH 03079<br/>
                </Location>
            }
            <DirectionsLink href={directionsUrl} target="_blank">Directions</DirectionsLink>
            <MapWrapper style={{ height: '50vh', width: (width > 576 ? '50%' : '100%') }}>
                <AddressDisplay
                    address="9 Hampshire Rd, Salem, NH 03079"
                    addressHeader="9 Hampshire Rd"
                    largeMapUrl={largeMapUrl}
                />
                <GoogleMapReact
                    bootstrapURLKeys={{key:'AIzaSyCvWA4eifvM0ZbYW0M0OhLg7Rdhwb_77Xw'}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    <MapPin
                        lat={defaultProps.center.lat}
                        lng={defaultProps.center.lng}
                        address="9 Hampshire Rd"
                    />
                </GoogleMapReact>
            </MapWrapper>
            <ViewLargeMapLink href={largeMapUrl} targer="_blank">View Larger Map</ViewLargeMapLink>

        </Wrapper>
    );
};

export default Salem;