import styled from 'styled-components';
import home_photo from '../images/home_photo.jpg';
import storage_types from '../images/storage_types.png';
import {useWindowSize} from "../utils/hooks";
import {useEffect} from "react";


const Wrapper = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;   
    width: 100%;
`;

const RightColumn = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
`;

const HomePhoto = styled.img`
    float: left;
    margin: 0 10px 10px 0;
`;

const Bottom = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    @media (max-width: 576px) {
        flex-direction: column; 
        padding: unset;
    }

`;

const StorageTypes = styled.img`
    width: 200px;
    height: 200px;
`;

const DetailsList = styled.ul`
    li {
        margin: 10px 0px;
    }
`;

const Home = ({tracker}) => {
    const {width} = useWindowSize();

    useEffect( () => {

        // This line will trigger on a route change
        tracker.pageview(window.location.pathname + window.location.search);

    });
    return(
        <Wrapper>
                <h2>Don't Ignore It. Store It!!</h2>
            {width > 576 ? <p>
                <HomePhoto src={home_photo}/>
                Welcome to Hampshire Road Self-Storage, proudly providing secure and reliable storage services to
                the greater Salem area!<br/><br/><br/>
                At Hampshire Road Self-Storage, you'll find a safe, well-maintained facility with easy access 7 days
                and unbeatable convenience. We offer clean units of many different sizes and capacities, so we're
                bound to have the perfect unit for all your needs.<br/><br/>
                Whether you need short-term storage for an excess of supplies or long term storage options for any
                variety of items from paperwork to home furnishings, Hampshire Road Self-Storage is guaranteed to
                have the perfect thing for your particular needs and requirements.<br/><br/><br/>
                And perhaps best of all, you'll save money with our low competitive rates. If affordable, reliable
                self-storage is important to you, call or visit Hampshire Road Self-Storage today!
            </p>
                :
                <>
                    <HomePhoto src={home_photo}/>
                    <br/>
                    Welcome to Hampshire Road Self-Storage, proudly providing secure and reliable storage services to
                    the greater Salem area!<br/><br/><br/>
                    At Hampshire Road Self-Storage, you'll find a safe, well-maintained facility with easy access 7 days
                    and unbeatable convenience. We offer clean units of many different sizes and capacities, so we're
                    bound to have the perfect unit for all your needs.<br/><br/>
                    Whether you need short-term storage for an excess of supplies or long term storage options for any
                    variety of items from paperwork to home furnishings, Hampshire Road Self-Storage is guaranteed to
                    have the perfect thing for your particular needs and requirements.<br/><br/><br/>
                    And perhaps best of all, you'll save money with our low competitive rates. If affordable, reliable
                    self-storage is important to you, call or visit Hampshire Road Self-Storage today!

                </>
            }
                <Bottom>
                    <StorageTypes src={storage_types}/>
                    <DetailsList>
                        <li>7 Days Access, 6 AM - 10 PM</li>
                        <li>Monthly Rentals</li>
                        <li>Many Sizes Available</li>
                    </DetailsList>
                </Bottom>
        </Wrapper>
    );
};

export default Home;