import React, {useEffect} from 'react';
import styled from 'styled-components';
import rates_photo from '../images/rates_photo.jpg';
import {useWindowSize} from "../utils/hooks";
// test
const Header = styled.h2`
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    ul {
        margin: 0px;
        margin-top: 10px;
        padding: 0;
        list-style-type: none;
    }
    @media (max-width: 576px) {
        margin-top: 10px; 
    }

`;

const RatesPhoto = styled.img`
    float: left;
    margin: 0 10px 10px 0;
`;

const Wrapper = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;   
    height: 100%;
    width: 100%;
    padding: 40px;
`;

const CaseListsWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 50px;
    @media (max-width: 576px) {
        flex-direction: column;
        padding-left: 15px;
    }
`;

const RatesTable = styled.table`
    border-collapse: collapse;
    margin-bottom: 20px;
    th {
        background: #fff;
        color: #980001;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 50%;
    }
    td{
        padding-top: 3px;
        padding-bottom: 3px;
    }
`;

const BorderedTableHeader = styled.th`
    border-left: 4px solid #980001; 
`;

const Rates = ({tracker}) => {

    const {width} = useWindowSize();

    useEffect( () => {

        // This line will trigger on a route change
        tracker.pageview(window.location.pathname + window.location.search);

    });

    return (
        <Wrapper>
            <Header>Rates</Header>
            {width > 576 ?
                <p>
                <RatesPhoto src={rates_photo}/>
                We can help both business and personal customers find a safe place to store those extra items.<br/><br/><br/>
                <b>Prices and Size Vary</b>
            </p>
            :
               <>
                   <RatesPhoto src={rates_photo}/>
                   We can help both business and personal customers find a safe place to store those extra items.<br/><br/><br/>
                   <b>Prices and Size Vary</b>
               </>
            }
            <CaseListsWrapper>
                <ListWrapper>
                    <b>Business:</b>
                    <ul>
                        <li>Office Record Storage</li>
                        <li>Un-used Office Equipment</li>
                        <li>Flea Market Goods</li>
                        <li>Carpentry/Mechanic Tools</li>
                    </ul>
                </ListWrapper>
                <ListWrapper>
                    <b>Personal:</b>
                    <ul>
                        <li>Seasonal Decorations</li>
                        <li>Memorabilia Storage</li>
                        <li>Seasonal Sports Equipment</li>
                        <li>Extra Household Items</li>
                    </ul>
                </ListWrapper>
            </CaseListsWrapper>
            <h3>Salem, NH Facility Rates</h3>
            <RatesTable>
                <tr>
                    <th>2nd Floor</th>
                    <BorderedTableHeader>Monthly Rental</BorderedTableHeader>
                </tr>
                <tr>
                    <td>5 x 6</td>
                    <td>$30.00</td>
                </tr>
                <tr>
                    <td>6 x 10 (60 sq.ft.)</td>
                    <td>$45.00</td>
                </tr>
                <tr>
                    <td>10 x 11 (110 sq. ft.)</td>
                    <td>$80.00</td>
                </tr>
            </RatesTable>

            <RatesTable>
                <tr>
                    <th>1st Floor</th>
                    <th></th>
                </tr>
                <tr>
                    <td>6 x 8 (48 sq. ft.)</td>
                    <td>$80.00</td>
                </tr>
                <tr>
                    <td>10 x 10 (100 sq. ft.)</td>
                    <td>$140.00</td>
                </tr>
                <tr>
                    <td>10 x 15 (150 sq. ft.)</td>
                    <td>$165.00</td>
                </tr>
                <tr>
                    <td>10 x 20 (200 sq. ft.)</td>
                    <td>$215.00</td>
                </tr>
                <tr>
                    <td>12 x 24 (288 sq. ft.)</td>
                    <td>$240.00</td>
                </tr>
            </RatesTable>
            <RatesTable>
                <tr>
                    <th>Auto/Boat/Motorcycle</th>
                    <th></th>
                </tr>
                <tr>
                    <td>5 x 10 (50 sq. ft.)</td>
                    <td>$65.00</td>
                </tr>
                <tr>
                    <td>10 x 10 (100 sq. ft.)</td>
                    <td>$100.00</td>
                </tr>
                <tr>
                    <td>10 x 20 (200 sq. ft.)</td>
                    <td>$160.00</td>
                </tr>
                <tr>
                    <td>12 x 24 (288 sq. ft.)</td>
                    <td>$200.00</td>
                </tr>
            </RatesTable>

            <h3>Methuen, MA Facility Rates (by appointment):</h3>
            <RatesTable>
                <tr>
                    <th>1st Floor</th>
                    <BorderedTableHeader>Monthly Rental</BorderedTableHeader>
                </tr>
                <tr>
                    <td>5 x 6 *</td>
                    <td>$50.00</td>
                </tr>
                <tr>
                    <td>4 x 9 *</td>
                    <td>$55.00</td>
                </tr>
                <tr>
                    <td>5 x 7 *</td>
                    <td>$60.00</td>
                </tr>
                <tr>
                    <td>5 x 8 *</td>
                    <td>$65.00</td>
                </tr>
                <tr>
                    <td>5 x 20 *</td>
                    <td>$70.00</td>
                </tr>
                <tr>
                    <td>5 x 10 *</td>
                    <td>$80.00</td>
                </tr>
                <tr>
                    <td>5 x 24 *</td>
                    <td>$80.00</td>
                </tr>
                <tr>
                    <td>5 x 9 *</td>
                    <td>$75.00</td>
                </tr>
                <tr>
                    <td>5 x 11 **</td>
                    <td>$90.00</td>
                </tr>
                <tr>
                    <td>7 x 9 *</td>
                    <td>$95.00</td>
                </tr>
                <tr>
                    <td>6 x 11 **</td>
                    <td>$100.00</td>
                </tr>
                <tr>
                    <td>5 x 13 **</td>
                    <td>$100.00</td>
                </tr>
                <tr>
                    <td>8 x 11 **</td>
                    <td>$110.00</td>
                </tr>
                <tr>
                    <td>11 x 20 **</td>
                    <td>$200.00</td>
                </tr>
                <tr>
                    <td>11 x 24 **</td>
                    <td>$230.00</td>
                </tr>
                <tr>
                    <td><i>* Interior hall units</i></td>
                    <td></td>
                </tr>
                <tr>
                    <td><i>** Drive-up access</i></td>
                    <td></td>
                </tr>
            </RatesTable>
            <RatesTable>
                <tr>
                    <th>1st Floor HEATED</th>
                    <th></th>
                </tr>
                <tr>
                    <td>5 x 7</td>
                    <td>$70.00</td>
                </tr>
                <tr>
                    <td>5 x 12</td>
                    <td>$105.00</td>
                </tr>
                <tr>
                    <td>6 x 11</td>
                    <td>$115.00</td>
                </tr>
                <tr>
                    <td>6 x 12</td>
                    <td>$120.00</td>
                </tr>
            </RatesTable>
            <RatesTable>
                <tr>
                    <th>2nd Floor</th>
                    <th></th>
                </tr>
                <tr>
                    <td>5 x 10</td>
                    <td>$35.00</td>
                </tr>
                <tr>
                    <td>5 x 20</td>
                    <td>$50.00</td>
                </tr>
                <tr>
                    <td>10 x 10</td>
                    <td>$45.00</td>
                </tr>
                <tr>
                    <td>5 x 24</td>
                    <td>$60.00</td>
                </tr>
                <tr>
                    <td>10 x 18</td>
                    <td>$100.00</td>
                </tr>
                <tr>
                    <td>11 x 20</td>
                    <td>$105.00</td>
                </tr>
            </RatesTable>
        </Wrapper>
    );
}

export default Rates;