import styled from 'styled-components';
import React from 'react';
import { MdPlace } from 'react-icons/md';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;  
    width: 145px;
`;

const AddressDisplay = styled.span`
    color: #fff;
    font-size: 14px;
    font-weight: bold;
`;

const MapPin = ({address}) => (
    <Wrapper>
        <MdPlace color='#e85a4f' size={42}/>
        <AddressDisplay>{address}</AddressDisplay>
    </Wrapper>
);

export default MapPin;