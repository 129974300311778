import React from 'react';
import styled from'styled-components';
import {CgMenu, CgClose} from 'react-icons/cg';

const ButtonWrapper = styled.div`
    cursor: pointer;
    z-index: 100;
    margin-right: 8px;
`;

const MenuButton = ({isActive, onClick}) => {
   return(
       <ButtonWrapper onClick={onClick}>
           { isActive ?
               <CgClose color='#fff' size={43}/>
               :
               <CgMenu color='#fff' size={42}/>
           }
       </ButtonWrapper>
   );
};

export default MenuButton;
