import styled from "styled-components";
import React from "react";


const AddressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    font-family: Roboto,Arial;
    font-size: 12px;
    left: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    position: absolute;
    top: 10px;
    z-index: 1000;
`;

const AddressDetails = styled.span`
    margin-bottom: 16px;
    margin-top: 6px;
`;

const AddressHeader = styled.span`
    font-size: 14px;
    font-weight: bold;
`;

const AddressDisplay = ({address, addressHeader, largeMapUrl}) => (
    <AddressWrapper>
        <AddressHeader>{addressHeader}</AddressHeader>
        <AddressDetails>{address}</AddressDetails>
        <a href={largeMapUrl} target="_blank">View larger map</a>
    </AddressWrapper>
);

export default AddressDisplay;