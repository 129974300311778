import React, {useState} from 'react';
import styled from'styled-components';
import pasillo from "../images/pasillo.jpg";
import logo from "../images/logo.png";
import PopoutMenu from "./PopoutMenu";
import MenuButton from "./MenuButton";

const Logo = styled.img`
    z-index: 100;
`;

const Wrapper = styled.header` 
   align-items: center;
   justify-content: space-between;
   display: flex;
   flex-direction: row;
   width: 100%;
   background-image: url(${pasillo});
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
`;

const BackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
`;

const MobileHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return(
        <Wrapper>
            <BackgroundOverlay/>
            <Logo height='80px' src={logo}/>
            <MenuButton isActive={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}/>
            <PopoutMenu isOpen={isMenuOpen} closeMenu={() => setIsMenuOpen(false)}/>
        </Wrapper>
    );
};

export default MobileHeader;