import React, {useState} from "react";
import styled from "styled-components";
import {pages} from "../constants/all";
import {Link, useLocation } from 'react-router-dom';


const Wrapper = styled.div`
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    border-top: 4px solid #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const MenuButton = styled(Link)`
    cursor: pointer;
    height: 100%;
    font-weight: bold;
    color: #fff;
    margin-right: 20px;
    text-align: bottom;
    display: flex;
    align-items: center;
    border-bottom: ${(props) => props.isSelected ? '4px solid #fff' : 'none'};
    text-decoration: none;
`;


const Menu = () => {
    const location = useLocation();
    return(
        <Wrapper>
            <MenuButton
                isSelected={location.pathname === `/${pages.HOME}` || location.pathname === '/'}
                to="/"
            >
                Home
            </MenuButton>
            <MenuButton
                isSelected={location.pathname === `/${pages.SALEM}`}
                to="/salem"
            >
                Salem Facility
            </MenuButton>
            <MenuButton
                isSelected={location.pathname === `/${pages.METHUEN}`}
                to="/methuen"
            >
                Methuen Facility
            </MenuButton>
            <MenuButton
                isSelected={location.pathname === `/${pages.RATES}`}
                to="/rates"
            >
                Rates
            </MenuButton>
        </Wrapper>
    );

};

export default Menu;
