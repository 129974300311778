import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 80px;
    z-index: 1001;
`;

const MenuButton = styled(Link)`
    width: 100%;
    padding: 10px;
    text-decoration: none;
    font-weight: bold;
    color: #000;
    cursor: pointer;
    border-bottom: 1px solid #000;
`;



const PopoutMenu = ({isOpen, closeMenu}) => {
    return isOpen ? (
        <Wrapper>
            <MenuButton
                onClick={closeMenu}
                to="/"
            >
                Home
            </MenuButton>
            <MenuButton
                onClick={closeMenu}
                to="/salem"
            >
                Salem
            </MenuButton>
            <MenuButton
                onClick={closeMenu}
                to="/methuen"
            >
                Methuen
            </MenuButton>
            <MenuButton
                onClick={closeMenu}
                to="/rates"
            >
                Rates
            </MenuButton>
        </Wrapper>
    ) : null;
};

export default PopoutMenu;