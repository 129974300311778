// test
import React, {useEffect} from 'react'
import './App.css';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import Home from './pages/Home';
import Salem from './pages/Salem';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import Methuen from './pages/Methuen';
import Rates from './pages/Rates';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import {useWindowSize} from "./utils/hooks";
import MobileHeader from "./components/MobileHeader";

const trackingId = "UA-5438845-1";
ReactGA.initialize(trackingId);


const Content = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%; 
    width: 100%;
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100vw;
    background-color: #980001;
`;

const App = () => {

  const {width} = useWindowSize();

  return (
      <Router>
          <Wrapper>
              { width > 576 ? <Header/> : <MobileHeader/> }
              <Content>
                  <SideMenu/>
                  <Switch>
                      <Route path="/salem">
                          <Salem tracker={ReactGA}/>
                      </Route>
                      <Route path="/methuen">
                          <Methuen tracker={ReactGA}/>
                      </Route>
                      <Route path="/rates">
                          <Rates tracker={ReactGA}/>
                      </Route>
                      <Route path="/">
                          <Home tracker={ReactGA}/>
                      </Route>
                  </Switch>
              </Content>
          </Wrapper>
      </Router>
  );
};

export default App;
