import React from 'react';
import styled from'styled-components';
import pasillo from "../images/pasillo.jpg";
import Menu from "./Menu";
import logo from "../images/logo.png";


const Wrapper = styled.header` 
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 215px; 
   background-image: url(${pasillo});
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
`;

const Logo = styled.img`
    position: absolute;
    z-index: 100;
    margin-bottom: 40px;
`;

const BackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
`;


const Header = () =>{
    return(
    <Wrapper>
        <BackgroundOverlay/>
        <Menu/>
        <Logo src={logo}/>
    </Wrapper>
    );
};

export default Header;
